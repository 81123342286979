import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
import { CiSearch } from "react-icons/ci";
import MyOfferForm from '../modals-form/MyOffersForm';
import axios from 'axios';


const Myoffers = () => {
  const [showMidiaIcons, setshowMidiaIcons] = useState(false);

  // for read api

const [apiData, setApiData] = useState([])
function getData(){
    axios.get('https://65e958d54bb72f0a9c513500.mockapi.io/carcar')
    .then((response) => {
        setApiData(response.data);
    })
}
useEffect(() => {
    getData();
}, [])
  
  return (
    <>
    
<div className="wrapper">
        <nav className={showMidiaIcons ? "sidebar mobile-menu-link" : "sidebar"}>
           <Navbar />
        </nav>
        <div className="content">
                    <button onClick={() => setshowMidiaIcons(!showMidiaIcons)} className="menubarbtn">menu</button>
          <h3>My offers Services</h3>
          <p>that the development build is not optimized.</p>
          <div className='service-modal-btn'>
          <MyOfferForm />
        </div>
          <form className="search-live">
        <div className="input-group mb-3">
            <div className="input-group-prepend">
              <button className="btn btn-outline-secondary" type="button" id="button-addon1"><CiSearch /></button>
            </div>
            <input type="text" className="form-control" placeholder="Search" aria-label="Example text with button addon" aria-describedby="button-addon1" />
          </div>
          
        </form>
       
        

      <div className="card">
        <div id="table-scroll" className="table-scroll">
  <table id="main-table" className="main-table">
    <thead>
    <tr>
    <th scope="col">Sn</th>
      <th scope="col">Category</th>
      <th scope="col">Sub-Category/Services name</th>
      <th scope="col">Price</th>
      <th scope="col">Edit/delete</th>
    
    </tr>
    </thead>
    <tbody>
    {/* <tr>
    <td>1</td>
      <td className="code-id">EXWTBKOUCFCdfhfgh gfhgfhfh fhgfhfhfhfg hfhfhfh fhfh</td>
      <td>2017 Honda Civic</td>
      <td>3DTREHI4FF</td>
      <td>Tire replacement</td>
      
    </tr> */}
    {
        apiData.map((item) => {
            return (
                <>
                    <tr>
                        <td className="code-id">{item.id}</td>
                        <td>{item.category}</td>
                        <td>{item.sub_category}/{item.other_sub_category}</td>
                        <td>${item.total_price}</td>
                        <td>
                            <button className='btn btn-dark btn-sm'>Edit</button>
                            <button className='btn btn-danger btn-sm'>Delete</button>
                        </td>
                        </tr>
                
                </>
            )

        })
     }
   

      </tbody>
    </table>
    </div>
      

          
        </div>
        
        </div>
    </div>
    </>

  )
}

export default Myoffers
