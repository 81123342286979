import React,{ useState } from 'react'
import Navbar from '../Navbar'


const Activeorders = () => {
  const [showMidiaIcons, setshowMidiaIcons] = useState(false);
  return (
    <>
 
 <div className="wrapper">
        <nav className={showMidiaIcons ? "sidebar mobile-menu-link" : "sidebar"} >
           <Navbar />
        </nav>
        <div className="content">
                    <button onClick={() => setshowMidiaIcons(!showMidiaIcons)} className='menubarbtn'>menu</button>
          This is active user
        </div>
    </div>
    </>
  )
}

export default Activeorders
