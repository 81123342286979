// import logo from './logo.svg';
 import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './components/pages/Home';
import Liverequests from './components/pages/Liverequests';
import Myoffers from './components/pages/Myoffers';
import Activeorders from './components/pages/Activeorders';
import Completedorders from './components/pages/Completedorders';
import Missedrequests from './components/pages/Missedrequests';
import Locations from './components/pages/Locations';
import Staff from './components/pages/Staff';


function App() {
  return (
    
   
    <BrowserRouter>
   
    
    <Routes>
       <Route path='/' element={<Home />} />
       <Route path='live-requests' element={<Liverequests />} />
       <Route path='my-offers' element={<Myoffers />} />
       <Route path='active-orders' element={<Activeorders />} />
       <Route path='completed-orders' element={<Completedorders />} />
       <Route path='missed-requests' element={<Missedrequests />} />
       <Route path='locations' element={<Locations />} />
       <Route path='staff' element={<Staff />} />
    </Routes>
    
    </BrowserRouter>
    
  );
}

export default App;
