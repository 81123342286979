import React, { useState } from 'react'
import Navbar from '../Navbar'
import { CiSearch } from "react-icons/ci";

const Liverequests = () => {
  const [showMidiaIcons, setshowMidiaIcons] = useState(false);
  return (
    <>
   
<div className="wrapper">
        <nav className={showMidiaIcons ? "sidebar mobile-menu-link" : "sidebar"} >
           <Navbar />
        </nav>
        <div className="content">
                    <button onClick={() => setshowMidiaIcons(!showMidiaIcons)} className="menubarbtn">menu</button>
          <h3>Live Requests</h3>
          <p>that the development build is not optimized.</p>
          <form className="search-live">
        <div className="input-group mb-3">
            <div className="input-group-prepend">
              <button className="btn btn-outline-secondary" type="button" id="button-addon1"><CiSearch /></button>
            </div>
            <input type="text" className="form-control" placeholder="Search" aria-label="Example text with button addon" aria-describedby="button-addon1" />
          </div>
        </form>

      <div className="card">
       
        <div id="table-scroll" className="table-scroll">
  <table id="main-table" className="main-table">
    <thead>
    <tr>
      <th scope="col">Request ID</th>
      <th scope="col">Vehicle</th>
      <th scope="col">VIN</th>
      <th scope="col">Requested Services</th>
      <th scope="col">Preferred drop-off time</th>
      <th scope="col">Request expires in</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td className="code-id">EXWTBKOUCFC</td>
      <td>2017 Honda Civic</td>
      <td>3DTREHI4FF</td>
      <td>Tire replacement</td>
      <td>Jul 8 9AM - 10AM</td>
      <td className="time">0:48</td>
    </tr>
    <tr>
      <td className="code-id">HGFSOUBCSV</td>
      <td>2021 Palsur CMOI</td>
      <td>FHT53XFIN</td>
      <td>Oil changes</td>
      <td>Jul 12 5AM - 6AM</td>
      <td className="time">5 minuts</td>
    </tr>
    <tr>
      <td className="code-id">EXWTBKOUCFC</td>
      <td>2017 Honda Civic</td>
      <td>3DTREHI4FF</td>
      <td>Tire replacement</td>
      <td>Jul 8 9AM - 10AM</td>
      <td className="time">0:48</td>
    </tr>
    <tr>
      <td className="code-id">HGFSOUBCSV</td>
      <td>2021 Palsur CMOI</td>
      <td>FHT53XFIN</td>
      <td>Oil changes</td>
      <td>Jul 12 5AM - 6AM</td>
      <td className="time">5 minuts</td>
    </tr>
    <tr>
      <td className="code-id">EXWTBKOUCFC</td>
      <td>2017 Honda Civic</td>
      <td>3DTREHI4FF</td>
      <td>Tire replacement</td>
      <td>Jul 8 9AM - 10AM</td>
      <td className="time">0:48</td>
    </tr>
    <tr>
      <td className="code-id">HGFSOUBCSV</td>
      <td>2021 Palsur CMOI</td>
      <td>FHT53XFIN</td>
      <td>Oil changes</td>
      <td>Jul 12 5AM - 6AM</td>
      <td className="time">5 minuts</td>
    </tr>
    <tr>
      <td className="code-id">EXWTBKOUCFC</td>
      <td>2017 Honda Civic</td>
      <td>3DTREHI4FF</td>
      <td>Tire replacement</td>
      <td>Jul 8 9AM - 10AM</td>
      <td className="time">0:48</td>
    </tr>
    <tr>
      <td className="code-id">HGFSOUBCSV</td>
      <td>2021 Palsur CMOI</td>
      <td>FHT53XFIN</td>
      <td>Oil changes</td>
      <td>Jul 12 5AM - 6AM</td>
      <td className="time">5 minuts</td>
    </tr>
    <tr>
      <td className="code-id">EXWTBKOUCFC</td>
      <td>2017 Honda Civic</td>
      <td>3DTREHI4FF</td>
      <td>Tire replacement</td>
      <td>Jul 8 9AM - 10AM</td>
      <td className="time">0:48</td>
    </tr>
    <tr>
      <td className="code-id">EXWTBKOUCFC</td>
      <td>2017 Honda Civic</td>
      <td>3DTREHI4FF</td>
      <td>Tire replacement</td>
      <td>Jul 8 9AM - 10AM</td>
      <td className="time">0:48</td>
    </tr>
    <tr>
      <td className="code-id">HGFSOUBCSV</td>
      <td>2021 Palsur CMOI</td>
      <td>FHT53XFIN</td>
      <td>Oil changes</td>
      <td>Jul 12 5AM - 6AM</td>
      <td className="time">5 minuts</td>
    </tr>
    <tr>
      <td className="code-id">EXWTBKOUCFC</td>
      <td>2017 Honda Civic</td>
      <td>3DTREHI4FF</td>
      <td>Tire replacement</td>
      <td>Jul 8 9AM - 10AM</td>
      <td className="time">0:48</td>
    </tr>
    <tr>
      <td className="code-id">HGFSOUBCSV</td>
      <td>2021 Palsur CMOI</td>
      <td>FHT53XFIN</td>
      <td>Oil changes</td>
      <td>Jul 12 5AM - 6AM</td>
      <td className="time">5 minuts</td>
    </tr>
    <tr>
      <td className="code-id">EXWTBKOUCFC</td>
      <td>2017 Honda Civic</td>
      <td>3DTREHI4FF</td>
      <td>Tire replacement</td>
      <td>Jul 8 9AM - 10AM</td>
      <td className="time">0:48</td>
    </tr>
    <tr>
      <td className="code-id">HGFSOUBCSV</td>
      <td>2021 Palsur CMOI</td>
      <td>FHT53XFIN</td>
      <td>Oil changes</td>
      <td>Jul 12 5AM - 6AM</td>
      <td className="time">5 minuts</td>
    </tr>
    <tr>
      <td className="code-id">EXWTBKOUCFC</td>
      <td>2017 Honda Civic</td>
      <td>3DTREHI4FF</td>
      <td>Tire replacement</td>
      <td>Jul 8 9AM - 10AM</td>
      <td className="time">0:48</td>
    </tr>
    <tr>
      <td className="code-id">HGFSOUBCSV</td>
      <td>2021 Palsur CMOI</td>
      <td>FHT53XFIN</td>
      <td>Oil changes</td>
      <td>Jul 12 5AM - 6AM</td>
      <td className="time">5 minuts</td>
    </tr>
    <tr>
      <td className="code-id">EXWTBKOUCFC</td>
      <td>2017 Honda Civic</td>
      <td>3DTREHI4FF</td>
      <td>Tire replacement</td>
      <td>Jul 8 9AM - 10AM</td>
      <td className="time">0:48</td>
    </tr>

      </tbody>
    </table>
    </div>
      

          
        </div>
        
        </div>
    </div>
    </>
  )
}

export default Liverequests
