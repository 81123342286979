import React from 'react'
import { NavLink } from 'react-router-dom';
import { LuDownload } from "react-icons/lu";
import { IoBagOutline } from "react-icons/io5";
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";
import { PiCopy } from "react-icons/pi";
import { RxCircleBackslash } from "react-icons/rx";
import { TfiLocationPin } from "react-icons/tfi";
import { FiUsers } from "react-icons/fi";
import { CiSearch } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { TiSocialDribbbleCircular } from "react-icons/ti";
import { FiDownload } from "react-icons/fi";



const Navbar = () => {
  return (
    <>
      <div className='navbarwrapper'>
      <ul className="nav flex-column">
      <li className="nav-item">
         {/* <h3>CarCare</h3> */}
        <form className="search">
        <div className="input-group mb-3">
            <div className="input-group-prepend">
              <button className="btn btn-outline-secondary" type="button" id="button-addon1"><CiSearch /></button>
            </div>
            <input type="text" className="form-control" placeholder="Search" aria-label="Example text with button addon" aria-describedby="button-addon1" />
          </div>
        </form>
       {/* <NavLink to="/"> </NavLink> */}
        </li>           
        <li className="nav-item">
       <NavLink to="/live-requests"><LuDownload /> Live requests <span className="alert-live">71</span> </NavLink>
        </li>  
            <li className="nav-item">
            <NavLink to="/my-offers"><IoBagOutline />  My offers/services</NavLink>
            </li>
            <li className="nav-item">
            <NavLink to="/active-orders"><HiOutlineWrenchScrewdriver /> Active Orders</NavLink>
            </li>
            <li className="nav-item">
            <NavLink to="/completed-orders"><PiCopy /> Completed Orders</NavLink>
            </li>
            <li className="nav-item">
            <NavLink to="/missed-requests"><RxCircleBackslash /> Missed Requests</NavLink>
            </li>
            <li className="nav-item">
            <NavLink to="/locations"><TfiLocationPin /> Locations</NavLink>
            </li>
            <li className="nav-item">
            <NavLink to="/staff"><FiUsers /> Staffs</NavLink>
            </li>
 
         </ul>
         <ul className="nav flex-column settings">
         <li className="nav-item">
            <NavLink to="/staff"><IoSettingsOutline /> Settings</NavLink>
            </li>
            <li className="nav-item">
            <NavLink to="/staff"><TiSocialDribbbleCircular /> Supports</NavLink>
            </li>
            <div className="help-center">
            <div className="autocenter-img">
              A
            </div>
            <div className="autocenter-details">
               <h6>CarCar auto center <FiDownload /></h6>
               <p>carcar@gmail.com</p>
            </div>
        </div>
        </ul>
        
      </div>
    </>
  )
}

export default Navbar
