
import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
//import Select from 'react-select'

function MyOffersForm() {
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  
  const [othercategory, setOthercategory ] = useState('');

  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const handleChangeSub = (event) => {
    setSelectedSubCategory(event.target.value);
  };
  const [othersubcategory, setOthersubcategory ] = useState('');
  const [price, setPrice] = useState('');


  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems({
      ...checkedItems,
      [name]: checked
    });
  };


  const navigate = useNavigate();

  const handleSubmit = (e) => {
    // e.preventDefault();
    axios.post('https://65e958d54bb72f0a9c513500.mockapi.io/carcar',{
      category: selectedCategory,
      other_category: othercategory,
      sub_category: selectedSubCategory,
      other_sub_category: othersubcategory,
      e_checkedItems: checkedItems,
      total_price: price

    }).then(() =>{
      navigate('my-offers');
    })
  }


  return (
    <div className='container'>
     <div className='model-wrapper'>
<button type="button" className="btn btn-dark" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Create your services
</button>
<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Create your  new services</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">

      <h6> {selectedCategory}, {othercategory}, {selectedSubCategory}, {othersubcategory}, {price}  </h6>
  
           <form onSubmit={handleSubmit}>
            <div className='form-group mb-2'>
                <label>Category</label>
                <select value={selectedCategory} onChange={handleChange} className='form-select'>
                <option value="" disabled>Select an option</option>
                <option value="Tire Service">Tire Service</option>
                <option value="Air Filters">Air Filters</option>
                <option value="Fluid changes"> Fluid changes</option>

                <option value="Wheel alignments"> Wheel alignments</option>
                <option value="Engine and battery services">Engine and battery services</option>
                <option value="Diagnostic and inspection services">Diagnostic and inspection services</option>

                <option value="Windshield services">Windshield services</option>
                <option value="Light Bulbs">Light Bulbs</option>
                <option value="A/C and heating service">A/C and heating service</option>

                <option value="Engine & Battery services">Engine & Battery services</option>
                <option value="Windshield services"> Windshield services</option>
                <option value="Belt replacement services">Belt replacement services</option>

                <option value="Break Service">Break Service</option>
                <option value="Axle & joint service">Axle & joint service</option>
                <option value="Shock & spring services">Shock & spring services</option>
                <option value="Interior Services">Interior Services</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className='form-group mb-2'>
            {selectedCategory === 'Others' && (
               <input type="text" className='form-control' placeholder="Enter other category" required onChange={(e) => setOthercategory(e.target.value)} />
              )}
              </div>
            <div className='form-group mb-2'>
                <label>Sub-Category</label>
                <select value={selectedSubCategory} onChange={handleChangeSub} className='form-select'>
                <option value="" disabled>Select an option</option>
                <option value="New Tires">New Tires</option>
                <option value="Flat tire patching repair">Flat tire patching repair</option>
                <option value="Tires Balancing">Tires Balancing</option>
                <option value="Tires rotation">Tires rotation</option>
                <option value="Sub-Others">Others</option>
              </select>
            </div>
            <div className='form-group mb-2'>
               {selectedSubCategory === 'Sub-Others' && (
               <input type="text" className='form-control' placeholder="Sub other category" required onChange={(e) => setOthersubcategory(e.target.value)} />
              )}
                {/* <label>if they select other in sub-category then we ask sub category tittle</label>
                <input type='text' className='form-control' placeholder='Enter your information' required onChange={(e) => setOthersubcategory(e.target.value)} /> */}
            </div>
            {/* <h4 className='mt-3'>if they select other we ask price in free input text also remove national Average price</h4> */}
           <div className='row'>
            <div className='col-md-7'>
                <label>Create your best price</label>
                    <div className="form-check">
                <input className="form-check-input" name='item1' type="checkbox" checked={checkedItems['item1'] || false} id="flexCheckDefault"  onChange={handleCheckboxChange} />
                <label className="form-check-label" for="flexCheckDefault">
                Item 1
                </label>
              </div>
                  
                    <div className="form-check">
                <input className="form-check-input" name='item2' type="checkbox" checked={checkedItems['item2'] || false} id="flexCheckDefault2"  onChange={handleCheckboxChange} />
                <label className="form-check-label" for="flexCheckDefault2">
                Item 2
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" name='item3' type="checkbox" checked={checkedItems['item3'] || false} id="flexCheckDefault3"  onChange={handleCheckboxChange} />
                <label className="form-check-label" for="flexCheckDefault3">
                Item 3
                </label>
              </div>
               
            </div>
            <div className='col-md-5'>
                <label>Set your price</label>
                <input type='number' className='form-control' placeholder='Enter your price' required onChange={(e) => setPrice(e.target.value)} />
            </div>
           </div>
           <h4 className='mt-3'>National Average Price: $100</h4>
           <div className='d-grid mt-4'>
           <input type='submit' value='Submit' className='btn btn-dark' />
           </div>
           </form>
      </div>
     
    </div>
  </div>
</div>
     </div>
    
    </div>
    
  )
}

export default MyOffersForm