import React, { useState } from 'react'
import Navbar from '../Navbar'

const Staff = () => {
  const [showMidiaIcons, setshowMidiaIcons] = useState(false);
  return (
    <>
   <div className="wrapper">
        <nav className={showMidiaIcons ? "sidebar mobile-menu-link" : "sidebar"} >
           <Navbar />
        </nav>
        <div className="content">
                    <button onClick={() => setshowMidiaIcons(!showMidiaIcons)} className='menubarbtn'>menu</button>
          Staff
        </div>
    </div>
   
    </>
  )
}

export default Staff
