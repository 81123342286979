import React, { useState } from 'react'
import Navbar from '../Navbar'


const Home = () => {
  const [showMidiaIcons, setshowMidiaIcons] = useState(false);

  return (
    <>
    <div className="wrapper">
        <nav className={showMidiaIcons ? "sidebar mobile-menu-link" : "sidebar"} >
           <Navbar />
        </nav>
        <div className="content">
          <div className="card">
            <div className="card-body">
            <button onClick={() => setshowMidiaIcons(!showMidiaIcons)} className='menubarbtn'>menu</button>
            <h1> Dashboard</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card mt-5">
                <div className="card-body">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mt-5">
                <div className="card-body">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mt-5">
                <div className="card-body">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                </div>
              </div>
            </div>
          </div>
                   
         
        </div>
    </div>
      
    </>
  )
}

export default Home